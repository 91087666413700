import React, { Component } from 'react';
import ActionButton from '../ActionButton/ActionButton';

class MotorScreen extends Component {

  constructor(props) {
    super(props);
    this.soundStore = props.soundStore;
  }
  
  render() {
    const {playSound, selectedSoundId, playerPlay} = this.props;
    const sounds = this.soundStore.getAllSoundsFromGenres('car-sounds');
    const actionButtons = sounds.map((sound) => {
      return <ActionButton 
          key={sound.id} 
          imageClass={sound.imageClass}
          playing={(selectedSoundId === sound.id && playerPlay ? true : false)} 
          id={sound.id} 
          onClick={playSound} 
        />;
    });

    return (
      <div className="motor-screen screen">
        {actionButtons}
      </div>
    );
  }
}

export default MotorScreen;