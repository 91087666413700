import React, { Component } from 'react';
import './top-bar.scss';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.state = {
      standByTime: 0,
      timeString: "00:00:00",
    }
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.updateTime();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  updateTime() {

    this.timerId = setInterval(() => {
      const { standByTime } = this.state;
      const newSecondsSum = standByTime + 1;
      const date = new Date(null);
      date.setSeconds(newSecondsSum);
      const timeString = `${(date.getUTCHours() < 10 ? '0'+date.getUTCHours() : date.getUTCHours())}:${(date.getUTCMinutes() < 10 ? '0'+date.getUTCMinutes() : date.getUTCMinutes())}:${(date.getUTCSeconds() < 10 ? '0'+date.getUTCSeconds() : date.getUTCSeconds())}`

      this.setState({
        standByTime: newSecondsSum,
        timeString
      });
    }, 1000);
  }

  handleClick = () => {
    const {changeScreen} = this.props;
    changeScreen('homescreen');
  };

  render() {

    const {selectedScreen} = this.props;
    let homebutton = '';

    if(selectedScreen !== 'homescreen') {
      homebutton = (
        <div className="home-button" onClick={this.handleClick}>
          <div className="icon"></div>
        </div>
      );
    }


    return (
      <div className="top-bar">
        <div className="drive-time">
          <div className="time">
            {this.state.timeString}
          </div>
        </div>
        {homebutton}
      </div>
    );
  }
}

export default TopBar;