import React, { Component } from 'react';
import './waiting-screen.scss';

class WaitingScreen extends Component {

  constructor(props) {
    super(props);
    this.hideWaitingScreen = this.hideWaitingScreen.bind(this);
  }
  
  hideWaitingScreen = () => {
    const {setStandbyMode} = this.props;
    setStandbyMode(false);
  }

  render() {
    const {standbyMode} = this.props;

    return (
      <div className={`waiting-screen ${standbyMode ? 'show' : 'hide'}`} onClick={this.hideWaitingScreen}>
        <div className="logo"></div>
      </div>
    );
  }
}

export default WaitingScreen;