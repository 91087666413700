import React, { Component } from 'react';
import './action-button.scss';

class ActionButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick = () => {
    const {id, onClick} = this.props;
    onClick(id);
  }

  render() {
    const { imageClass, title, playing } = this.props;
    return (
      <div className={`action-button ${playing ? 'playing' : ''}`} onClick={this.handleClick}>
        <div className={`image music ${imageClass ? imageClass : ''}`}></div>
        <div className={`label ${title ? '' : 'icon--play'} ${playing ? 'playing' : ''}`}>
          <div className="title">{title || ''}</div>
        </div>
      </div>
    );
  }
}

export default ActionButton;