import React, { Component } from 'react';
import './gauges-screen.scss';

class GaugesScreen extends Component {
  constructor(props) {
    super(props);
    this.apiKey = '41baad6c8a6e382a46b137d47bfaaef9';
    this.cityId = '3067696';
    this.state = {
      temperature: 28,
      online: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }
  
  componentDidMount() {
    const apiCallUrl = `https://api.openweathermap.org/data/2.5/weather?id=${this.cityId}&mode=json&units=metric&cnt=7&APPID=${this.apiKey}`;
    fetch(apiCallUrl)
    .then((response) => {
      return response.json();
    })
    .then((jsonResponse) => {
      this.setState({temperature: Math.round(jsonResponse.main.temp), online: true});
    })
    .catch(() => {
      this.setState({temperature: 26, online: true});
    });
  }

  temperaturePointerRotate() {
    const {temperature} = this.state;
    // 14 = -45deg
    // 24 = 0deg
    // 34 = 45deg
    const oneDeg = 4.5;
    let pointerDeg = (temperature - 24) * oneDeg;
    if(temperature < 24) {
      pointerDeg = pointerDeg * -1;
    }
    if(temperature < 14) {
      pointerDeg = -45;
    }
    if(temperature > 34) {
      pointerDeg = 45;
    }

    return pointerDeg;
  }

  fuelPointerRotate() {
    const {fuelPercent} = this.props;

    // 0 = -45deg
    // 50 = 0deg
    // 100 = 45deg

    const oneDeg = 0.9;
    let pointerDeg = (fuelPercent - 50) * oneDeg;

    if(fuelPercent <= 0) {
      pointerDeg = -45;
    }
    if(fuelPercent >= 100) {
      pointerDeg = 45;
    }

    return Math.round(pointerDeg);

  }

  handleClick() {
    const {fuelToFull} = this.props;
    fuelToFull();
  }

  render() {
    const {temperature, online} = this.state;
    const {fuelTanking} = this.props;

    return (
      <div className="gauges-screen screen">
        <div className="fuel-gauge">
          <div className="scale">
            <div className="pointer" style={{transform: `rotate(${this.fuelPointerRotate()}deg)`}}></div>
          </div>
          <div className="action">
            <div className={`tank-button ${fuelTanking ? 'tanking' : '' }`} onClick={this.handleClick}></div>
          </div>
        </div>
        <div className="temperature-gauge">
          <div className="scale">
            <div className="pointer" style={{transform: `rotate(${this.temperaturePointerRotate()}deg)`}}></div>
          </div>
          <div className="action">
            <div className={`value ${online ? 'value--online' : ''}`}>{temperature} °C</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GaugesScreen;