import React, { Component } from 'react';
import ActionButton from '../ActionButton/ActionButton';
import './sirene-screen.scss';

class SireneScreen extends Component {

  constructor(props) {
    super(props);
    this.soundStore = props.soundStore;
    this.state = {
      showStrobo: false,
    }

    this.stroboScreenToggle = this.stroboScreenToggle.bind(this);
  }

  stroboScreenToggle() {
    const {showStrobo} = this.state;
    this.setState({showStrobo: !showStrobo});
  }
  
  render() {
    const {playSound, selectedSoundId, playerPlay} = this.props;
    const {showStrobo} = this.state;
    const sounds = this.soundStore.getAllSoundsFromGenres('sirene-sounds');
    const actionButtons = sounds.map((sound) => {
      return <ActionButton 
          key={sound.id} 
          imageClass={sound.imageClass}
          playing={(selectedSoundId === sound.id && playerPlay ? true : false)} 
          id={sound.id} 
          onClick={playSound} 
        />;
    });

    return (
      
      <div className="sirene-screen screen">
        <div className={`strobo-screen ${showStrobo ? 'show' : 'hide'}`} onClick={this.stroboScreenToggle}>
          <div className="strobo-screen__red-light"></div>
          <div className="strobo-screen__blue-light"></div>
        </div>
        <div className="strobo-button" onClick={this.stroboScreenToggle} />
        {actionButtons}
      </div>
    );
  }
}

export default SireneScreen;