import React, { Component } from 'react';

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.soundStore = props.soundStore;
    this.audioPlayerRef = React.createRef();
  }

  componentDidMount() {
    const {setPlayerReference} = this.props;
    this.registerControlEvents();
    setPlayerReference(this.audioPlayerRef.current);
  }
  
  registerControlEvents() {
    const {setPlayState} = this.props;
    this.audioPlayerRef.current.addEventListener("play", () => {
      setPlayState(true);
    });
    this.audioPlayerRef.current.addEventListener("ended", () => {
      setPlayState(false);
    });
    this.audioPlayerRef.current.addEventListener("pause", () => {
      setPlayState(false);
    });
  }

  componentDidUpdate(prevProps) {
    const {selectedSoundId} = this.props;
    // NEW SOURCE
    if (selectedSoundId !== null && 
        selectedSoundId !== prevProps.selectedSoundId
    ) {
      const sound = this.soundStore.getSoundById(selectedSoundId);
      this.audioPlayerRef.current.src = sound.src;
      //this.audioPlayerRef.current.src = "data:audio/mp3;base64," + sound.src;
      this.audioPlayerRef.current.play();
    } 
  }

  render() {
    return (
      <audio className="audio-player" ref={this.audioPlayerRef}></audio>
    );
  }
}

export default AudioPlayer;