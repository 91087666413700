import React from 'react';
import './app.scss';
import SoundStore from './components/SoundStore/SoundStore';
import WaitingScreen from './components/WaitingScreen/WaitingScreen';
import TopBar from './components/TopBar/TopBar';
import HomeScreen from './components/HomeScreen/HomeScreen';
import MotorScreen from './components/MotorScreen/MotorScreen';
import SireneScreen from './components/SireneScreen/SireneScreen';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import MusicScreen from './components/MusicScreen/MusicScreen';
import GaugesScreen from './components/GaugesScreen/GaugesScreen';
import WarnScreen from './components/WarnScreen/WarnScreen';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.soundStore = new SoundStore();
    this.playerReference = null;
    this.state = {
      selectedScreen: 'homescreen',
      selectedSoundId: null,
      playerPlayState: false,
      standbyMode: true,
      fuelPercent: 80,
      fuelTanking: false,
      warnActive: false,
      warn: {
        text: 'Řidič se musí napít',
        icon: 'bottle-icon',
      }
    };

    this.fuelSound = this.soundStore.getSoundByTitle('fueltank');
    this.warnSound = this.soundStore.getSoundByTitle('warnsound');
    this.fuelToFullInterval = null;
    this.fuelToEmptyInterval = null;

    this.inactivityTreasholdSec = 60;
    this.inactivityTimeSec = 0;
    this.inactivityInterval = null;

    this.warnTimeout = null;
    this.warnTimeoutMs = 25*(1000*60);
    this.warns = [
      {
        text: 'Řidič se musí napít',
        icon: 'bottle-icon',
      },
      {
        text: 'Zkontrolujte gumy',
        icon: 'tire-icon',
      },
      {
        text: 'Zkontrolujte světla',
        icon: 'bulb-icon',
      },      
    ];
  }

  componentDidMount() {
    setTimeout(() => {
      this.setStandbyMode(false);
      this.fuelToEmpty();
      this.checkInactivity();
      this.warningsChecker();
    }, 2500);
  }

  changeScreen = (selectedScreen) => {
    this.setState({selectedSoundId: null, selectedScreen});
    this.playerReference.pause();
    this.checkInactivity();
  }

  setPlayerReference = (playerReference) => {
    this.playerReference = playerReference;
  }

  setStandbyMode = (standbyMode) => {
    this.setState({standbyMode});
    if(!standbyMode) {
      this.checkInactivity();
    }
  }

  checkInactivity = () => {
    clearInterval(this.inactivityInterval);
    this.inactivityTimeSec = 0;
    this.inactivityInterval = setInterval(() => {
      this.inactivityTimeSec += 1;
      if(this.inactivityTimeSec > this.inactivityTreasholdSec) {
        this.setStandbyMode(true);
      } 
    }, 1000);
  }

  fuelToFull = () => {
    const {fuelPercent, fuelTanking} = this.state;
    if(fuelPercent > 98 && !fuelTanking) {
      this.playSound(this.warnSound.id);
    } else if (!fuelTanking) {
      clearInterval(this.fuelToEmptyInterval);
      this.playSound(this.fuelSound.id);
      const intervalTimeMs = Math.round(17000 / (100-fuelPercent));
      let fuelPercentUpdate = fuelPercent;
      this.fuelToFullInterval = setInterval(() => {
        if(fuelPercentUpdate < 100) {
          fuelPercentUpdate+=1;
          this.setState({fuelPercent: fuelPercentUpdate, fuelTanking: true});
          this.checkInactivity();
        } else {
          clearInterval(this.fuelToFullInterval);
          this.setState({fuelTanking: false});
          this.fuelToEmpty();
        }
      }, intervalTimeMs);
    }
  }

  fuelToEmpty = () => {
    const {fuelPercent} = this.state;
    const intervalTimeMs = 18000;
    let fuelPercentUpdate = fuelPercent;

    this.fuelToEmptyInterval = setInterval(() => {
      if(fuelPercentUpdate > 0) {
        fuelPercentUpdate-=1;
        this.setState({fuelPercent: fuelPercentUpdate});
      } else {
        clearInterval(this.fuelToEmptyInterval);
        this.setState({ warn: {
          text: 'Natankujte palivo',
          icon: 'fuel-icon',
        }})
        this.setWarnState(true);
      }
    }, intervalTimeMs);
  }

  playSound = (soundId) => {
    const {selectedSoundId} = this.state;
    if(soundId !== selectedSoundId) {
      this.setState({selectedSoundId: soundId});
    } else {
      if(this.playerReference.paused) {
        this.playerReference.currentTime = 0;
        this.playerReference.play();
      } else {
        this.playerReference.pause();
      }
      this.checkInactivity();
    }
  }

  setPlayerPlayState = (playerPlayState) => {
    this.setState({playerPlayState});
  }

  setWarnState = (warnActive) => {
    this.checkInactivity();

    if(!warnActive) {
      this.warningsChecker();
      this.setState({warnActive, standbyMode: false});
    } else {
      this.playSound(this.warnSound.id);
      this.setState({warnActive});
    }

  }

  warningsChecker = () => {
    const {fuelTanking} = this.state;
    this.warnTimeout = setTimeout(() => {
      if(!fuelTanking) {
        const warn = this.warns[Math.floor(Math.random()*this.warns.length)];
        this.setState({warn});
        this.setWarnState(true);
      } else {
        clearTimeout(this.warnTimeout);
        this.warningsChecker();
      }
    }, this.warnTimeoutMs);
  }
  
  renderActualScreen() {
    const { selectedScreen, selectedSoundId, playerPlayState, fuelPercent, fuelTanking } = this.state;
    switch(selectedScreen) {
      case 'homescreen':
        return <HomeScreen changeScreen={this.changeScreen}/>;
      case 'motorscreen':
        return <MotorScreen 
          soundStore={this.soundStore} 
          selectedSoundId={selectedSoundId} 
          playSound={this.playSound}
          playerPlay={playerPlayState}
        />;
      case 'sirenescreen':
          return <SireneScreen 
            soundStore={this.soundStore} 
            selectedSoundId={selectedSoundId} 
            playSound={this.playSound}
            playerPlay={playerPlayState}
          />;
      case 'musicscreen':
          return <MusicScreen 
            soundStore={this.soundStore} 
            selectedSoundId={selectedSoundId} 
            playSound={this.playSound}
            playerPlay={playerPlayState}
          />;
      case 'gaugesscreen':
        return <GaugesScreen 
          fuelToFull={this.fuelToFull}
          fuelPercent={fuelPercent}
          fuelTanking={fuelTanking}
        />;
      default:
        return <HomeScreen changeScreen={this.changeScreen} />;
    }
  }

  render() {
    const {selectedScreen, selectedSoundId, standbyMode, warn, warnActive} = this.state;
    const screen = this.renderActualScreen();

    return (
      <div className="app">
        <AudioPlayer 
          soundStore={this.soundStore} 
          selectedSoundId={selectedSoundId} 
          setPlayState={this.setPlayerPlayState}
          setPlayerReference={this.setPlayerReference}
        />
        <WarnScreen warn={warn} warnActive={warnActive} setWarnState={this.setWarnState}/>
        <WaitingScreen standbyMode={standbyMode} setStandbyMode={this.setStandbyMode}/>
        <div className="main-screen">
          <TopBar selectedScreen={selectedScreen} changeScreen={this.changeScreen}/>
          <div className="screens">
           {screen}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
