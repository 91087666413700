import engineStartSound from './assets/sounds/engineStart.mp3';
import brakesSound from './assets/sounds/brakeSound.mp3';
import throtleUpSound from './assets/sounds/throtleUp.mp3';
import carSound from './assets/sounds/carSound.mp3';
import fuelSound from './assets/sounds/fuelSound.mp3';


import hornSound from './assets/sounds/hornSound.mp3';
import hornSound2 from './assets/sounds/hornSound2.mp3';

import driftSound from './assets/sounds/driftSound.mp3';
import driftSound2 from './assets/sounds/driftSound2.mp3';
import driftSound3 from './assets/sounds/driftSound3.mp3';

import acelerationSound from './assets/sounds/acelerationSound.mp3';
import acelerationSound2 from './assets/sounds/acelerationSound2.mp3';
import acelerationSound3 from './assets/sounds/acelerationSound3.mp3';

import sirene from './assets/sirene/sireneSound.mp3';
import sirene2 from './assets/sirene/sireneSound2.mp3';
import sirene3 from './assets/sirene/sireneSound3.mp3';
import sirene4 from './assets/sirene/sireneSound4.mp3';
import sirene5 from './assets/sirene/sirene1.mp3';
import sirene6 from './assets/sirene/sirene2.mp3';
import sirene7 from './assets/sirene/sirene3.mp3';


import smouloveSong from './assets/music/smouliParty.mp3';
import smouloveSong2 from './assets/music/smouliPartyTimber.mp3';
import babySharkSong from './assets/music/babyShark.mp3';
import gummyBearSong from './assets/music/gummyBear.mp3';
import crazyFrogSong from './assets/music/crazyFrog.mp3';
import toyStorySong from './assets/music/toyStory.mp3';
import carsSong from './assets/music/cars.mp3';

import warnSound from './assets/sounds/warnSound.mp3';




export default class SoundStore {
  constructor() {
    this.soundsByGenres = {};
    this.soundsByIds = [];
    this.createSoundsStore();

    this.soundsByGenres.forEach(genre => {
      genre.sounds = this.shuffleArray(genre.sounds);
    });

    //this.preloadSounds();

    window.soundsStore = this;
  }

  // preloadSounds() {
  //   const sounds = this.getAllSounds();
  //   sounds.forEach((genre) => {
  //     genre.sounds.forEach((sound) => {
  //       const audioElement = document.createElement('audio');
  //         audioElement.src = sound.src;
  //         audioElement.type = 'audio/mpeg';
  //         audioElement.setAttribute("preload", "auto");
  //         document.getElementById('preload-songs').appendChild(audioElement);
  //     });
  //   });
  // }

  getAllSoundsFromGenres(searchGenre) {
    const result = this.soundsByGenres.filter(genre => genre.title === searchGenre);
    if (result.length === 0) {
      return null;
    }
    return result[0].sounds;
  }

  getSoundById(id) {
    const result = this.soundsByIds.filter(sound => sound.id === id);
    if (result.length === 0) {
      return null;
    }
    return result[0];
  }

  getSoundByTitle(title) {
    const result = this.soundsByIds.filter(sound => sound.title === title);
    if (result.length === 0) {
      return null;
    }
    return result[0];
  }

  createSoundsStore() {
    const sounds = this.getAllSounds();
    sounds.forEach((genre) => {
      this.soundsByGenres[genre.title] = genre.sounds.map((sound) => {
        sound.id = this.createUnigueId();
        this.soundsByIds.push(sound);
        return sound;
      });
    })
    this.soundsByGenres = sounds;
  }


  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  createUnigueId() {
    const hashIdCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const hashIdLength = 5;

    let uniqueId = `sound_`;
    for (var i = 0; i < hashIdLength; i++) {
      uniqueId += hashIdCharacters.charAt(Math.floor(Math.random() * hashIdCharacters.length));
    }

    return uniqueId;
  }

  getAllSounds() {
    return [{
        title: 'warn',
        sounds: [{
            title: 'warnsound',
            src: warnSound,
            imageClass: 'warn',
          },
          {
            title: 'fueltank',
            src: fuelSound,
            imageClass: 'fuel',
          },
        ]
      },
      {
        title: 'music',
        sounds: [{
            title: 'smouli party',
            src: smouloveSong,
            imageClass: 'smurf',
          },
          {
            title: 'smouli party timber',
            src: smouloveSong2,
            imageClass: 'smurf2',
          },
          {
            title: 'babyShark',
            src: babySharkSong,
            imageClass: 'babyshark',
          },
          {
            title: 'gummy bear',
            src: gummyBearSong,
            imageClass: 'gummybear',
          },
          {
            title: 'crazy frog',
            src: crazyFrogSong,
            imageClass: 'crazyfrog',
          },
          {
            title: 'toy story',
            src: toyStorySong,
            imageClass: 'toystory',
          },
          {
            title: 'cars',
            src: carsSong,
            imageClass: 'cars',
          },
        ]
      },
      {
        title: 'car-sounds',
        sounds: [{
            title: 'engine start',
            src: engineStartSound,
            imageClass: 'keys',
          },
          {
            title: 'car sound',
            src: carSound,
            imageClass: 'motor2',
          },
          {
            title: 'brakes',
            src: brakesSound,
            imageClass: 'breaks',
          },
          {
            title: 'horn 2',
            src: hornSound2,
            imageClass: 'horn',
          },
          {
            title: 'throtle up',
            src: throtleUpSound,
            imageClass: 'motor2',
          },
          {
            title: 'horn triple',
            src: hornSound,
            imageClass: 'horn',
          },
          {
            title: 'drift',
            src: driftSound,
            imageClass: 'drift',
          },
          {
            title: 'drift 2',
            src: driftSound2,
            imageClass: 'drift',
          },
          {
            title: 'drift 3',
            src: driftSound3,
            imageClass: 'drift',
          },
          {
            title: 'acceleration',
            src: acelerationSound,
            imageClass: 'turbo',
          },
          {
            title: 'acceleration 2',
            src: acelerationSound2,
            imageClass: 'turbo',
          },
          {
            title: 'acceleration 3',
            src: acelerationSound3,
            imageClass: 'turbo',
          },
        ]
      },
      {
        title: 'sirene-sounds',
        sounds: [{
            title: 'sirene 1',
            src: sirene,
            imageClass: 'sirene2',
          },
          {
            title: 'sirene 2',
            src: sirene2,
            imageClass: 'sirene2',
          },
          {
            title: 'sirene 3',
            src: sirene3,
            imageClass: 'sirene2',
          },
          {
            title: 'sirene 4',
            src: sirene4,
            imageClass: 'sirene2',
          },
          {
            title: 'sirene 5',
            src: sirene5,
            imageClass: 'sirene3',
          },
          {
            title: 'sirene 6',
            src: sirene6,
            imageClass: 'sirene3',
          },
          {
            title: 'sirene 7',
            src: sirene7,
            imageClass: 'sirene3',
          }
        ]
      }
    ]
  }


}

/**
 * import engineStartSound from './assets/sounds/engineStart.mp3';
import brakesSound from './assets/sounds/brakeSound.mp3';
import throtleUpSound from './assets/sounds/throtleUp.mp3';
import carSound from './assets/sounds/carSound.mp3';
import fuelSound from './assets/sounds/fuelSound.mp3';


import hornSound from './assets/sounds/hornSound.mp3';
import hornSound2 from './assets/sounds/hornSound2.mp3';

import driftSound from './assets/sounds/driftSound.mp3';
import driftSound2 from './assets/sounds/driftSound2.mp3';
import driftSound3 from './assets/sounds/driftSound3.mp3';

import acelerationSound from './assets/sounds/acelerationSound.mp3';
import acelerationSound2 from './assets/sounds/acelerationSound2.mp3';
import acelerationSound3 from './assets/sounds/acelerationSound3.mp3';

import sirene from './assets/sirene/sireneSound.mp3';
import sirene2 from './assets/sirene/sireneSound2.mp3';
import sirene3 from './assets/sirene/sireneSound3.mp3';
import sirene4 from './assets/sirene/sireneSound4.mp3';
import sirene5 from './assets/sirene/sirene1.mp3';
import sirene6 from './assets/sirene/sirene2.mp3';
import sirene7 from './assets/sirene/sirene3.mp3';


import smouloveSong from './assets/music/smouliParty.mp3';
import smouloveSong2 from './assets/music/smouliPartyTimber.mp3';
import babySharkSong from './assets/music/babyShark.mp3';
import gummyBearSong from './assets/music/gummyBear.mp3';
import crazyFrogSong from './assets/music/crazyFrog.mp3';
import toyStorySong from './assets/music/toyStory.mp3';
import carsSong from './assets/music/cars.mp3';

import warnSound from './assets/sounds/warnSound.mp3';




TXT


import engineStartSound from './assets/base64/engineStart.mp3';
import brakesSound from './assets/base64/brakeSound.mp3';
import throtleUpSound from './assets/base64/throtleUp.mp3';
import carSound from './assets/base64/carSound.mp3';
import fuelSound from './assets/base64/fuelSound.mp3';

import hornSound from './assets/base64/hornSound.mp3';
import hornSound2 from './assets/base64/hornSound2.mp3';

import driftSound from './assets/base64/driftSound.mp3';
import driftSound2 from './assets/base64/driftSound2.mp3';
import driftSound3 from './assets/base64/driftSound3.mp3';

import acelerationSound from './assets/base64/acelerationSound.mp3';
import acelerationSound2 from './assets/base64/acelerationSound2.mp3';
import acelerationSound3 from './assets/base64/acelerationSound3.mp3';

import sirene from './assets/base64/sireneSound.mp3';
import sirene2 from './assets/base64/sireneSound2.mp3';
import sirene3 from './assets/base64/sireneSound3.mp3';
import sirene4 from './assets/base64/sireneSound4.mp3';
import sirene5 from './assets/base64/sirene1.mp3';
import sirene6 from './assets/base64/sirene2.mp3';
import sirene7 from './assets/base64/sirene3.mp3';


import smouloveSong from './assets/base64/smouliParty.mp3';
import smouloveSong2 from './assets/base64/smouliPartyTimber.mp3';
import babySharkSong from './assets/base64/babyShark.mp3';
import gummyBearSong from './assets/base64/gummyBear.mp3';
import crazyFrogSong from './assets/base64/crazyFrog.mp3';
import toyStorySong from './assets/base64/toyStory.mp3';
import carsSong from './assets/base64/cars.mp3';

import warnSound from './assets/base64/warnSound.mp3';




 */