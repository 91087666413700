import React, { Component } from 'react';
import './warn-screen.scss';

class WarnScreen extends Component {

  
  handleClick = () => {
    const {setWarnState} = this.props;
    setWarnState(false);
  }

  render() {

    const {warn, warnActive} = this.props;

    return (
      <div className={`warn-screen ${warnActive ? 'show' : 'hide'}`}>
        <div className={`warn-icon ${warn.icon}`}></div>
        <div className="warn-action">
          <div className="text">{warn.text}</div>
          <div className="action-button" onClick={this.handleClick}></div>
        </div>
      </div>
    );
  }
}

export default WarnScreen;