import React, { Component } from 'react';
import ActionButton from '../ActionButton/ActionButton';
import './home-screen.scss';

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.menuItems = [
      {
        title: 'music',
      },
      {
        title: 'motor',
      },
      {
        title: 'sirene',
      },
      {
        title: 'gauges',
      }
    ]
  }

  render() {

    const {changeScreen} = this.props;

    const actionButtons = this.menuItems.map((item) => {
      return <ActionButton key={item.title} imageClass={item.title} title={item.title} id={`${item.title}screen`} onClick={changeScreen} />;
    });

    return (
      <div className="home-screen screen center-items four-items">
        {actionButtons}
      </div>
    );
  }
}

export default HomeScreen;